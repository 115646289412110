<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="55%"
  >
    <div class="editTitle">视频信息</div>

    <div class="edit-main">
      <el-form
        :model="editForm"
        label-width="120px"
        :rules="rules"
        ref="editVersionForm"
      >
        <el-row>
          <!-- <el-col :span="12">
            <el-form-item label="序列号" prop="cameSn">
              <el-input
                size="small"
                v-model="editForm.cameSn"
                placeholder="请输入序列号"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="接入类型" prop="cameraType">
              <el-select
                class="block-select"
                size="small"
                v-model="editForm.cameraType"
                placeholder="请选择接入类型"
              >
                <el-option
                  v-for="(item, index) in cameTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通道号" prop="cameChannel">
              <el-input
                size="small"
                v-model="editForm.cameChannel"
                placeholder="请输入通道号"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="监控名称" prop="cameType">
              <el-select
                class="block-select"
                size="small"
                v-model="editForm.cameType"
                placeholder="请选择监控名称"
              >
                <el-option
                  v-for="(item, index) in videoList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="使用状态" prop="cameStatus">
              <el-select
                class="block-select"
                size="small"
                v-model="editForm.cameStatus"
                placeholder="请选择使用状态"
              >
                <el-option
                  v-for="(item, index) in cameStatusList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="监控IMEI" prop="deviceSn">
              <el-select
                class="block-select"
                filterable
                size="small"
                clearable
                v-model="editForm.deviceSn"
                placeholder="请选择监控IMEI"
              >
                <el-option
                  v-for="item in terminalgetList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属项目" prop="projId">
              <el-select
                class="block-select"
                size="small"
                clearable
                filterable
                v-model="editForm.projId"
                placeholder="请选择所属项目"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="24">
            <el-form-item label="备注" prop="cameNote">
              <el-input
                size="small"
                type="textarea"
                :rows="2"
                v-model="editForm.cameNote"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <!-- <el-button
                  type="success"
                  size="small"
                 @click="travelClick"
                  icon="el-icon-plus"
                 
                  class="btn"
                  >新增监控</el-button
                > -->
      <el-button
        type="success"
        size="small"
        @click="travelClick"
        icon="el-icon-plus"
        class="addBtn"
        >新增监控</el-button
      >
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="cameSn" label="序列号" align="center">
          <template slot-scope="scope">
            <el-input
              size="small"
              :rules="{ required: true, message: '请输入序列号', trigger: 'blur' }"
              v-model="scope.row.cameSn"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="cameraType" label="接入类型" align="center">
          <template slot-scope="scope">
            <el-select
              class="block-select"
              size="small"
              v-model="scope.row.cameraType"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in cameTypeList"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="cameChannel" label="通道号" align="center">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.cameChannel"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="cameType" label="监控名称" align="center">
          <template slot-scope="scope">
            <el-select
                class="block-select"
                size="small"
                v-model="scope.row.cameType"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in videoList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="cameStatus" label="使用状态" align="center">
          <template slot-scope="scope">
            <el-select
                class="block-select"
                size="small"
                v-model="scope.row.cameStatus"
                placeholder="请选择使用状态"
              >
                <el-option
                  v-for="(item, index) in cameStatusList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="cameNote" label="备注" align="center">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.cameNote"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100px">
          <template slot-scope="scope">
            <span @click="detailItemTravel(scope.$index)" class="detail">
              <i class="el-icon-delete"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
import sysManage from "../../api/modules/sysManage";
import basic from "../../api/modules/basic";
import {
  equipType,
  deviceState,
  cameTypeList,
  cameStatusList,
  videoList,
} from "../../config/dataStatus";
import { organizationCascader, phoneReg } from "../../utils/tool";
import organizationTree from "../../api/modules/organizationTree";

export default {
  name: "videoEdit",
  components: { CustomDialog },
  props: ["visible", "title", "item"],
  data() {
    return {
      editForm: {
      
      },
      rules: {
        cameSn: [{ required: true, message: "请输入序列号", trigger: "blur" }],

        cameType: [
          { required: true, message: "请输入监控名称", trigger: "blur" },
        ],
        cameChannel: [
          { required: true, message: "请输入通道号", trigger: "blur" },
        ],
        cameStatus: [
          { required: true, message: "请选择使用状态", trigger: "change" },
        ],
        deviceSn: [
          { required: true, message: "请选择监控IMEI", trigger: "change" },
        ],
        cameraType: [
          { required: true, message: "请选择接入类型", trigger: "change" },
        ],
      },
      uploadUrl: config.uploadUrl,
      videoList: videoList,
      equipTypeList: equipType,
      deviceStateList: deviceState,
      parentList: [],
      copanyListInfo: [],
      cameTypeList: cameTypeList,
      cameStatusList: cameStatusList,
      projectList: [],
      terminalgetList: [],
      tableData: [],
      // equipType: equipType
    };
  },

  mounted() {
    this.getProjectList();
    this.getterminalGetList();
 
  },
  methods: {
    travelClick() {
      const newDeviceCamera = {
        cameSn: "", // 序列号
        cameraType: "", // 接入类型
        cameChannel: "", // 通道号
        cameType: "", // 监控名称
        cameStatus: 0, // 使用状态
        cameNote: "", // 备注
      };
      this.tableData.push(newDeviceCamera);
    },
    detailItemTravel(index) {
      this.tableData.splice(index, 1);
    },
    colseDialog() {
      this.$emit("close");
    },
    getProjectList() {
      sysManage.GetProjectList().then((res) => {
        res.data.map((item) => {
          this.projectList.push({
            label: item.projName,
            value: item.projId,
          });
        });
      });
    },
    getterminalGetList() {
      basic.terminalGetList().then((res) => {
        res.data.map((item) => {
          this.terminalgetList.push({
            label: item.deviceImei,
            value: item.deviceImei,
          });
        });
      });
    },
    cascaderEditChange(val) {
      let valLen = val.length;
      this.editForm.orgaId = val[valLen - 1];
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submit() {
     
      let params = {
        addDeviceCameraVOList:this.tableData,
        deviceSn:this.editForm.deviceSn,
        projId:this.editForm.projId
      }
      console.log(params,'params');

      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
          sysManage.addCamera(params).then((res) => {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.resetForm("editVersionForm");
            this.$emit("done");
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
.addBtn {
  background-color: #008f4d;
}
</style>
