<template>
  <div class="pageMain">
    <search-head :model="searchForm" @search="search" @reset="reset">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="序列号">
          <el-input
            v-model="searchForm.cameSn"
            size="small"
            placeholder="序列号"
          ></el-input>
        </el-form-item>
        <el-form-item label="使用状态" prop="cameStatus">
                <el-select
                  class="block-select"
                  size="small"
                  v-model="searchForm.cameStatus"
                  placeholder="请选择使用状态"
                >
                  <el-option
                    v-for="(item, index) in cameStatusList"
                    :key="index"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
        <el-form-item label="项目名称">
          <el-input
            v-model="searchForm.projName"
            size="small"
            placeholder="项目名称"
          ></el-input>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
      :data="tableData"
      :headerCellStyle="headerCellStyle"
      @selectionChange="handleSelectionChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
    
      :total="total"
      title="视频备案"
    >
      <template slot="btns">
        <el-button
        type="success"
          size="small"
          @click="addFrom"
          icon="el-icon-plus"
          class="addBtn"
          >新增</el-button
        >
      </template>
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="cameStatus" label="使用状态" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.cameStatus == 0" type="success">使用 </el-tag>
          <el-tag v-if="scope.row.cameStatus == 1" type="warning">闲置</el-tag>
          <!-- <el-tag v-if="scope.row.deviceState == 2" type="danger">损坏</el-tag> -->
        </template>
      </el-table-column>
      <el-table-column prop="cameSn" align="center" label="序列号">
      </el-table-column>
      <el-table-column prop="cameraType" align="center" label="接入类型">
        <template slot-scope="scope">
          <span v-if="scope.row.cameraType == 0">萤石云</span>
          <span v-if="scope.row.cameraType == 2">博瓦</span>
          <span v-if="scope.row.cameraType == 1">自有</span>
        </template>
      </el-table-column>
      <el-table-column prop="cameChannel" align="center" label="通道号">
      </el-table-column>
      <el-table-column prop="projName" align="center" label="所属项目">
      </el-table-column>
      <el-table-column prop="createTime" align="center" label="添加时间">
      </el-table-column>

      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          
          <span @click="deleteItem(scope.row)" class="detail delete">
            <i class="el-icon-delete"></i>
          </span>
        </template>
      </el-table-column>
    </custom-tables>
    <videoEdit
      :visible.sync="showDialog"
      @close="colseDialog"
      :title="title"
      @done="doneDialog"
      :item="item"
    ></videoEdit>
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import videoEdit from "../../../components/systemManage/videoEdit.vue";
import sysManage from "../../../api/modules/sysManage";
import {cameStatusList} from '../../../config/dataStatus'
export default {
  name: "index",
  components: { videoEdit, CustomTables, SearchHead },
  data() {
    return {
      tableData: [],
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      showDialog: false,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      title:'',
      item:{},
      cameStatusList:cameStatusList
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      sysManage.getCameraListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getList();
    },
    addFrom() {
      this.showDialog = true;
      this.title="新增"
    },
    colseDialog() {
      this.showDialog = false;
    },
    doneDialog(){
      this.showDialog = false;
      this.getList();
    },
    submit() {
      this.showDialog = false;
    },
    detailItem() {
      //详情
    },
    deleteItem(item) {
      console.log(item, 'item');
      this.$confirm(`此操作将永久删除设备${item.cameSn}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //{staffId:[item.staffId]}
        //  return
        sysManage.delCamera(item.cameId).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      })
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  },
};
</script>

<style scoped lang="less">
.detail {
  color: #008f4d;
}
.delete {
  margin-left: 10px;
}
.addBtn {
  background-color: #008f4d;
}
</style>
